import React from 'react';
import Link from '/src/components/LocalizedLink';

import Layout from '../../components/Layout';

import IconWhatsApp from '../../assets/images/svg/whatsapp.inline.svg';
import IconArrowRight from '../../assets/images/svg/arrow-right.inline.svg';

import '../../assets/styles/pages/terms-and-privacy-page.scss';

const ApplyThankYou = () => {
  return (
    <Layout
      title="Thank you for registering"
      description="Your message has been received and we will get back to you shortly."
    >
      <div className="terms-and-privacy-page">
        <div className="container">
          <div className="wrapper">
            <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
              Thank you for registering
            </h1>
            <div className="subsection">
              <p className="subsection__par">
                You have been successfully registered and we will email you a link to the webinar.
              </p>
              <p className="subsection__par">
                <b>Please check your spam folder if you do not hear from us!</b>
              </p>
              <p className="subsection__par">The TEFL Iberia Team</p>
              <Link to="/" className="c-btn c-btn--red mt-50">
                Return to the home page
                <IconArrowRight />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyThankYou;
